import PropTypes from "prop-types";
import { createContext, useEffect, useReducer, useState } from "react";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";

// project imports
import Loader from "ui-component/Loader";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { openSnackbar } from "store/slices/snackbar";
import useRequestIdGenerater from "customhooks";
import useTimeStamp from "reuseableTable/uniquetimestamp/GenerateUniqueTimeStamp";
import { browserName, isMobile, osName } from "react-device-detect";

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: window.localStorage.getItem("isLoggedIn"),
  isInitialized: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const RequestId = useRequestIdGenerater();
  const navigate = useNavigate();
  const { rendomRequestId, generateTimeStamp } = useTimeStamp();

  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org/?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  const init = async () => {
    try {
      const serviceToken = window.localStorage.getItem("serviceToken");
      const response = await axios.get("/v1/user/me", {
        headers: {
          Authorization: `Bearer ${serviceToken}`,
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      });
      axios
        .get(
          `/v1/institute/get?instituteId=${response.data.data.instituteId}`,
          {
            headers: {
              Authorization: `Bearer ${serviceToken}`,
              "Content-Type": "application/json",
              requestId: RequestId(
                Math.floor(1000 + Math.random() * 9999),
                Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
              ),
            },
          }
        )
        .then((response) => {
          localStorage.setItem("batchEnabled", response.data.data.batch);
          localStorage.setItem(
            "branchEnabled",
            response.data.data.branchEnabled
          );
          localStorage.setItem("programEnabled", response.data.data.program);
          localStorage.setItem("logo", response?.data?.data?.logo?.data);
          localStorage.setItem(
            "institutionType",
            response?.data?.data?.institutionType
          );
        });
      localStorage.setItem("userTypes", response.data.data.userType);
      localStorage.setItem("userName", response.data.data.userName);
      localStorage.setItem("instituteId", response.data.data.instituteId);
      localStorage.setItem("userId", response?.data?.data?.id);

      // localStorage.setItem("firstLogin", response?.data?.data?.firstLogin);
      dispatch({
        ...state,
        type: LOGIN,
        payload: {
          isLoggedIn: window.localStorage.getItem("isLoggedIn"),
          user: response.data.data,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: LOGOUT,
      });
    }
  };

  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    generateTimeStamp();
  }, []);

  const getExternalUserDetails = async () => {
    // const serviceToken = window.localStorage.getItem("serviceToken");

    const response = await axios.get(`/v1/user/get/external-user`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("serviceToken")}`,
        "Content-Type": "application/json",
        requestId: RequestId(
          Math.floor(1000 + Math.random() * 9999),
          Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
        ),
      },
    });

    return response.data;
  };


  const login = async (username, password) => {
    const response = await axios.post(
      `/v1/auth/signin`,
      {
        username,
        password,
      },
      {
        headers: {
          "Content-Type": "application/json",
          requestId: RequestId(
            Math.floor(1000 + Math.random() * 9999),
            Math.floor(1000 + Math.random() * 9999 + rendomRequestId)
          ),
        },
      }
    );

    const { token, user } = response.data;

    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("role", response.data.role[0].roleName);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("serviceToken", response.data.token);
    localStorage.setItem("userName", response.data.userName);
    localStorage.setItem("emailVerified", response?.data?.emailVerified);
    setSession(response.data.token);

    if (response?.data?.emailVerified !== false) {
      const externalUserDetails = await getExternalUserDetails();

      if (externalUserDetails && externalUserDetails.data?.apiKey) {
        await loginToUniversity(externalUserDetails.data.apiKey);
      }

    }



    if (response?.data?.emailVerified === false) {
      navigate(`/otp/page/${username}/${password}`);
    } else {
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: response.data.data,
        },
      });
      getDeviceDetails();
      window.location.reload();
    }

    const externalUserDetails = await getExternalUserDetails();

    if (externalUserDetails && externalUserDetails.data?.apiKey) {
      await loginToUniversity(externalUserDetails.data.apiKey);
    }


    generateTimeStamp();

  };



  const loginToUniversity = async (externalToken) => {
    const response = await axios.post(
      `https://api.entraruniversity.com/api/v1/auth/signin`,
      {
        username: externalToken,
        password: externalToken,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const { token, user } = response.data;


    localStorage.setItem("external-token", response.data.data.token);
    // setSession(response.data.token);
    // window.location.reload();

    generateTimeStamp();
  };

  const getDeviceDetails = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    const payload = {
      browserName: browserName,
      osName: osName,
      mobileDevice: isMobile,
      ip: ipAddress,
    };

    const response = await axios.post(`/v1/logs/login/add`, payload, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        "Content-Type": "application/json",
        requestId: RequestId(browserName, ipAddress + rendomRequestId),
      },
    });

    generateTimeStamp();
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post("/api/account/register", {
      id,
      email,
      password,
      firstName,
      lastName,
    });
    let users = response.data;

    if (
      window.localStorage.getItem("users") !== undefined &&
      window.localStorage.getItem("users") !== null
    ) {
      const localUsers = window.localStorage.getItem("users");
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`,
        },
      ];
    }

    window.localStorage.setItem("users", JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    // localStorage.removeItem('isLoggedIn');
    localStorage.clear();
    dispatch({ type: LOGOUT });
  };

  const resetPassword = (Password, token) => {
    const data = {
      password: Password,
      username: token,
    };
    axios
      .post(`/v1/auth/password/change`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("role", response.data.data.role[0].roleName);
        localStorage.setItem("collegeId", response.data.data.collageId);
        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("id", response.data.data.id);
        localStorage.setItem("userTypes", response.data.data.userType);
        localStorage.setItem("userName", response.data.data.userName);
        setSession(response.data.data.token);
        if (response.data.data.firstLogin === false) {
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: response.data.data,
            },
          });
          window.location.reload();
        }
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            open: true,
            message: "Somthing went wrong",
            variant: "alert",
            alert: {
              color: "error",
            },
            close: false,
          })
        );
      });
  };

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
